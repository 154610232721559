<template>
  <div id="detail">
    <div class="list">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/order' }"
          >订单列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>订单详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="border"></div>
      <div class="detailcontet"
      v-loading="loading"
       :data="info"
      >
        <ul class="product">
          <li class="items">
            <div class="img">
              <img :src="info.thumb_img" alt="" />
            </div>
            <div class="name">
              <h6 v-text="info.title"></h6>
              <p class="num">
                数量: <span>X{{ info.product_cnt }}</span>
              </p>
              <p class="price">
                价格: <span>{{ info.fromprice }}</span>
              </p>
            </div>
          </li>
        </ul>
         <div class="border"></div>
         <div class="big-box" v-if='info.address_info'>
             <div class="pay">
            <h6>收货地址</h6>
            <p class="items">
              用户姓名: <span>{{ info.address_info.user_name }}</span>
            </p>
            <p class="items">
              联系方式: <span>{{ info.address_info.tel_number }}</span>
            </p>
            <p class="items">
              收货地址: <span>{{info.address_info.province_name+info.address_info.city_name+info.address_info.county_name+info.address_info.detail_info}}</span>
            </p>
          </div>
         </div>
        <div class="border"></div>
        <div class="big-box">
          <div class="pay">
            <h6>支付信息</h6>
            <p class="items">
              订单状态: <span>{{ info.statusText }}</span>
            </p>
            <p class="items">
              订单金额: <span>{{ info.fromprice }} 元</span>
            </p>
            <p class="items">
              支付方式: <span>微信支付</span>
            </p>
          </div>
           <div class="pay">
            <h6>厂商信息</h6>
            <p class="items">
              厂商信息: <span>{{ info.toshopname?info.toshopname:'云仓'}}</span>
            </p>
            <p class="items">
              出厂价格: <span>{{ info.toprice }} 元</span>
            </p>
            <p class="items">
              运费方式: <span>默认包邮</span>
            </p>
          </div>
        </div>
         <div class="border"></div>
       <el-button type="primary" round class="btn" @click="push">{{info.zhuangtai}}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderDetail",
  data() {
    return {
      info: "",
      loading:true,
    };
  },
  methods: {
    detail() {
      // this.$route.params.id
      this.axios
        .get(this.api.orderdetail, {
          params: {
            order_id: this.$route.params.id
          }
        })
        .then(res => {
          this.info = res;
          let status = res.status;
          this.loading=false;
          switch (status) {
            case 10:
              this.info.statusText = "待付款";
              break;
            case 20:
              this.info.statusText = "待发货";
              break;
            case 30:
              this.info.statusText = "待收货";
              break;
            case 100:
              this.info.statusText = "已完成";
              break;
            case 200:
              this.info.statusText = "取消订单";
              break;
            case 250:
              this.info.statusText = "取消付款/超时取消";
              break;
          }
        });
    },
    // 申请发货
    push(){
        let msg;
        if(this.info.code==-1){
            msg=this.info.msg;
            this.$alert(msg, '提示', {
            confirmButtonText: '确定',
                callback: () => {
                    this.$message({
                    type: 'info',
                    message:'已取消'
                });
            }
        });
        }else{
            msg='即将申请发货，将扣除您的余额'+this.info.toprice+'元，确认继续？'
            this.$confirm(msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type:this.info.code==-1?'info':'warning'
                }).then(() => {
                  this.pay()
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        }
    },
    pay(){
        this.axios.get(this.api.pay,{
            params:{
                 order_id: this.$route.params.id
            }
        })
        .then((res)=>{
            if(res.code==1){
                this.$message({
                    type: 'success',
                    message: '申请成功!'
                });
            }else{
                this.$message({
                    type: 'info',
                    message:res.msg
                });
            }
        })
    }
  },
  created() {
    this.detail();
  }
};
</script>